import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { IntlProvider } from "../../context/IntlContext";
import { GlobalContext } from "../../context/GlobalContext";
import { Header, HelpCenter, Footer, PageNotFound } from "../common";
import "../../styles/main.scss";
import { ErrorCatch } from "../common/ErrorCatch";
import { PcContainer } from "../Coefficient/PcContainer";
import { SsblContainer } from "../Coefficient/SsblContainer";
import { ToastContainer } from "react-toastify";
import { RetentionRateContainer } from "../RetentionRate/RetentionRateContainer";
import { Authentication } from "../common/Authentication";
import { QueryClient, QueryClientProvider } from "react-query";

const App: React.FC = () => {
  /*   const matomo = createInstance({
    urlBase: process.env.REACT_APP_MATOMO_SERVER!,
    siteId: parseInt(process.env.REACT_APP_MATOMO_SITE_ID!, 10),
    trackerUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.php`,
    srcUrl: `${process.env.REACT_APP_MATOMO_SERVER}piwik.js`,
  }); */
  const context = {};
  const queryClient = new QueryClient();

  return (
    <ErrorCatch>
      <IntlProvider>
        <ToastContainer
          hideProgressBar={true}
          position="top-center"
          style={{ whiteSpace: "pre-line", width: "35em" }}
        />
        <GlobalContext.Provider value={context}>
          <QueryClientProvider client={queryClient}>
          <React.StrictMode>
            <Router basename="/">
              <Header />
              <main id="main" role="main" className="container-fluid d-flex flex-column">
                <div className="flex-grow-1">
                  <Authentication>
                    <Routes>
                      <Route path="/" element={<Navigate replace to="/pc" />} />
                      <Route path="/pc" Component={PcContainer} />
                      <Route path="/ssbl" Component={SsblContainer} />
                      <Route path="/rr" Component={RetentionRateContainer} />
                      <Route path="*" Component={PageNotFound} />
                    </Routes>
                  </Authentication>
                </div>
                <HelpCenter />
              </main>
              <Footer />
            </Router>
            </React.StrictMode>
            </QueryClientProvider>
        </GlobalContext.Provider>
      </IntlProvider>
    </ErrorCatch>
  );
};

export default App;
