import App from "./components/App/App";
import { SgwtConnectHTMLElement } from "./sgwt-widgets";
import { createRoot } from 'react-dom/client';

// Load SG Bootstrap v4 CSS
import "@sg-bootstrap/icons/dist/index.css";
import "@sg-bootstrap/core/dist/css/sg-bootstrap-standard.css";

// Auto-login with SG Connect, using the <sgwt-connect> widget.
const widget = document.querySelector<SgwtConnectHTMLElement>("sgwt-connect");
if (widget === null) {
  document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
} else if (widget.sgwtConnect) {
  // Widget is already initialized, so can make the SG Connect login...
  login();
} else {
  // Widget is not yet initialized, we need to wait for the "ready" event.
  widget.addEventListener("sgwt-connect--ready", login);
}

function login() {
  if (!widget) {
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        No widget found to handle the authentication on this application!
      </div>`;
    return;
  }
  const sgwtConnect = widget.sgwtConnect;
  widget.removeEventListener("sgwt-connect--ready", login);

  if (sgwtConnect.getAuthorizationError()) {
    // Case #1: an error occurred during the retrieval of the SG Connect token...
    document.body.innerHTML = `
      <div class="alert alert-danger" role="alert">
        Authorization error: ${sgwtConnect.getAuthorizationError()}.
      </div>`;
  } else if (sgwtConnect.isAuthorized()) {
    // Case #2: the user is authenticated, we load the application
    const container = document.getElementById('root');
    if(container){
      createRoot(container).render(<App/>);
    }
  } else {
    // Case #3: the user is not authenticated, we request an authorization...
    sgwtConnect.requestAuthorization();
  }
}

