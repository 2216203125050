import { get } from "lodash";
import { stringifyParams } from "../components/utils/strings/stringUtils";
import { toastifyError, toastifySuccess } from "../components/utils/toastify/toasterNotifier";
import { getAuthorizationHeader, getUserEmail } from "../helpers";
import { getErrorMessage } from "./api.helpers";
import { HttpRequestHeader, ToastMessage } from "./api.typings";

type Request =
  | {
      method: "GET";
      headers: HttpRequestHeader;
      url: string;
      params: object | undefined;
    }
  | {
      method: "POST" | "PUT" | "PATCH";
      headers: HttpRequestHeader;
      url: string;
      body: BodyInit;
    };

function createRequest(request: Request, includeGetDefaultHeader = true) {
  if (request.method === "GET") {
    const { url, params, method, headers } = request;
    const requestUrl = `${url}${stringifyParams(params)}`;
    return fetch(requestUrl, { method, headers: { ...headers } });
  } else {
    const { url, body, method, headers } = request;
    const requestUrl = `${url}`;
    return fetch(requestUrl, { method, headers: { ...headers }, body });
  }
}

async function tryCatch(
  f: () => Promise<Response>,
  toastMessage?: ToastMessage,
  needToastifySuccess = false,
  returnAsJson = true
): Promise<any> {
  try {
    const result = await f();
    if (!result.ok) {
      toastifyError(await getErrorMessage(result, toastMessage));
      return result;
    }
    if (needToastifySuccess) {
      toastifySuccess(toastMessage ? toastMessage.successMessage : "");
    }
    if (returnAsJson) {
      return await result.json();
    }
    return result;
  } catch (error) {
    toastifyError(get(toastMessage, "friendlyErrorMessage") || error);
    return null;
  }
}

export const createRepository = () => ({
  get: <T>(url: string, headers: HttpRequestHeader, params?: object, toastMessage?: ToastMessage): Promise<T> => {
    const getFetch = () => createRequest({ method: "GET", headers, url, params }, false);
    return tryCatch(getFetch, toastMessage);
  },
});

export const getAuthenticationHeader = (): HttpRequestHeader => ({
  authorization: getAuthorizationHeader(),
  userEmail: getUserEmail(),
});
