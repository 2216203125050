import React from "react";
import { FormattedMessage } from "react-intl";
import { NavLink } from "react-router-dom";
import { AccountCenter } from "./AccountCenter";
import clsx from "clsx";

interface HeaderState {
  collapsed: boolean;
}

const MenuItems: React.FC = () => (
  <>
    <li className="navbar-item">
      <NavLink to="/pc" className="navbar-link">
        <FormattedMessage id="menu.pc" />
      </NavLink>
    </li>
    <li className="navbar-item">
      <NavLink to="/ssbl" className="navbar-link">
        <FormattedMessage id="menu.ssbl" />
      </NavLink>
    </li>
    <li className="navbar-item">
      <NavLink to="/rr" className="navbar-link">
        <FormattedMessage id="menu.retentionrate" />
      </NavLink>
    </li>
  </>
);

export class Header extends React.Component<{}, HeaderState> {
  state = {
    collapsed: true,
  };

  toggle = () => {
    this.setState(({ collapsed }) => ({
      collapsed: !collapsed,
    }));
  };

  render() {
    return (
      <nav className="navbar border-bottom border-top">
        <div className="navbar-title w-100">
          <NavLink to="/" className="navbar-title-link ">
            <div className="navbar-logo">
              <img
                src="https://shared.sgmarkets.com/assets/images/socgen_logo_full.svg"
                // src="https://shared.sgmarkets.com/assets/images/socgen_logo_muted.svg"
                alt="SG"
                className="border border-white"
              />
            </div>
            <div className="navbar-title-divider"></div>
            <div className="navbar-service-name d-lg-inline-block">Client Contribution</div>
          </NavLink>
          <button
            className="navbar-menu-btn btn btn-flat btn-xl btn-icon flex-center"
            type="button"
            onClick={() => this.toggle()}
            data-bs-offset="0,4"
            aria-expanded="true"
          >
            <i className="icon">menu</i>
          </button>
          <ul className={clsx('navbar-menu-dropdown dropdown-menu w-100', !this.state.collapsed && 'show')} data-bs-popper="static">
            <MenuItems />
          </ul>
        </div>
        <div className="navbar-content">
          <ul className="navbar-navigation m-0">
            <MenuItems />
          </ul>
        </div>
        <AccountCenter />
      </nav>
    );
  }
}
