import { PcCoefficientsDto, SsblCoefficientsDto } from "../types/coefficient.typing";
import { LabelAndIdDto, LabelsAndIdsDto } from "../types/common.typing";
import { createRepository, getAuthenticationHeader } from "./api";
import { ICoefficientApi } from "./coefficient.api.typing";

export const CoefficientApi: ICoefficientApi = {
    getTermTypeIdsAndLabels: (): Promise<LabelAndIdDto[]> => createRepository().get<LabelsAndIdsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/coefficients/termtypes`,
        getAuthenticationHeader()
    ).then(res => res.values),
    getPcIdsAndLabels: (): Promise<LabelAndIdDto[]> => createRepository().get<LabelsAndIdsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/coefficients/pcs/labels`,
        getAuthenticationHeader()
    ).then(res => res.values),
    getPcCoefficients: (ids: number[], termTypes: number[]): Promise<PcCoefficientsDto> => createRepository().get<PcCoefficientsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/coefficients/pcs`,
        getAuthenticationHeader(),
        { id: ids, termType: termTypes }
    ),
    getSsblIdsAndLabels: (): Promise<LabelAndIdDto[]> => createRepository().get<LabelsAndIdsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/coefficients/ssbls/labels`,
        getAuthenticationHeader()
    ).then(res => res.values),
    getSsblCoefficients: (ids: number[], termTypes: number[]): Promise<SsblCoefficientsDto> => createRepository().get<SsblCoefficientsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/coefficients/ssbls`,
        getAuthenticationHeader(),
        { id: ids, termType: termTypes }
    ),
};