import React from "react";
import { CoefficientApi } from "../../api/coefficient.api";
import { AgGridReact } from "ag-grid-react";
import { CoefficientDto } from "../../types/coefficient.typing";
import { CoefficientSearchFilter } from "./CoefficientSearchFilter";
import { ColDef } from "ag-grid-community";
import { dateToString } from "../utils/mappers/mapperUtils";

export const PcContainer: React.FC = () => {
  const DEFAULT_PAGINATION_SIZE = 10;
  const [pcCoefficients, setPcCoefficients] = React.useState<CoefficientDto[]>([]);
  const columns: ColDef<CoefficientDto>[] = [
    { field: "pcMnemo", headerName: "PC Mnemo", flex: 1, initialSort: "asc" },
    { field: "termType", flex: 1 },
    { field: "cap", flex: 1 },
    { field: "floor", flex: 1 },
    { field: "updatedBy", flex: 1 },
    { field: "updatedDate", flex: 1, valueFormatter: (date) =>  dateToString(date.value)},
  ];

  const search = (selectedPcCoefficientIds: number[], selectedTermTypeIds: number[]) => {
    CoefficientApi.getPcCoefficients(selectedPcCoefficientIds, selectedTermTypeIds).then((res) =>
      setPcCoefficients(res.pcCoefficients)
    );
  };

  return (
    <div className="main-content">
      <div className="row pl-2">
        <div className="col-12 col-sm-8 p-4">
          <h3 className="display-3">C-Profit Client Contribution</h3>
          <h4>PC configuration</h4>
        </div>
      </div>

      <CoefficientSearchFilter
        coefficientLabel="PC"
        fetchCoefficientIdsAndLabels={CoefficientApi.getPcIdsAndLabels}
        onSearch={search}
      ></CoefficientSearchFilter>

      <hr />

      <div className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          domLayout="autoHeight"
          rowData={pcCoefficients}
          columnDefs={columns}
          pagination={true}
          paginationPageSize={DEFAULT_PAGINATION_SIZE}
          onGridReady={() => search([], [])}
          suppressHorizontalScroll={true}
          tooltipShowDelay={1000}
          paginationPageSizeSelector={[10, 25, 50]}
        ></AgGridReact>
      </div>
    </div>
  );
};
