import { LabelDto, LabelsDto } from "../types/common.typing";
import { RetentionRatesDto } from "../types/retentionrate.typing";
import { createRepository, getAuthenticationHeader } from "./api";
import { IRetentionRateApi } from "./retentionrate.api.typing";

export const RetentionRateApi: IRetentionRateApi = {
    getPcMnemos: (): Promise<LabelDto[]> => createRepository().get<LabelsDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/retentionrate/pcmnemos`,
        getAuthenticationHeader()
    ).then(res => res.values),
    getRetentionRates: (pcMnemos: string[], creditDateMonths: number[]): Promise<RetentionRatesDto> => createRepository().get<RetentionRatesDto>(
        `${process.env.REACT_APP_CLIENTCONTRIBUTION_API}/api/v1/retentionrate`,
        getAuthenticationHeader(),
        { creditDateMonth: creditDateMonths, pcMnemo: pcMnemos }
    )
};