import React from "react";
import { SgPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { ItemsSelection, SgPickerCustomEvent } from "@sg-bootstrap/components";
import { Label } from "reactstrap";
import { CoefficientApi } from "../../api/coefficient.api";
import { LabelAndIdDto } from "../../types/common.typing";

export interface CoefficientSearchFilterProps{
    coefficientLabel: string,
    fetchCoefficientIdsAndLabels: () => Promise<LabelAndIdDto[]>
    onSearch: (coefficientIds: number[], termTypeIds: number[]) => void,
}

export const CoefficientSearchFilter: React.FC<CoefficientSearchFilterProps> = ({coefficientLabel, onSearch, fetchCoefficientIdsAndLabels}: CoefficientSearchFilterProps) => {
  const [selectedCoefficientIds, setSelectedCoefficientIds] = React.useState<number[]>([]);
  const [selectedTermTypeIds, setSelectedTermTypeIds] = React.useState<number[]>([]);

  const fetchIdsAndLabels = async (event: SgPickerCustomEvent<string>) => {
    const idsAndMnemos = await fetchCoefficientIdsAndLabels();
    event.target.setItems(idsAndMnemos);
  };

  const fetchTermTypeIdsAndLabels = async (event: SgPickerCustomEvent<string>) => {
    const termTypeIdsAndMnemos = await CoefficientApi.getTermTypeIdsAndLabels();
    event.target.setItems(termTypeIdsAndMnemos);
  };

  
  const addKeyToSelectedList = (event: SgPickerCustomEvent<ItemsSelection>, list: number[], setList: React.Dispatch<React.SetStateAction<number[]>>) => {
    const selectedItem = event.detail.items.at(0);
    if(selectedItem){
      setList([...list, selectedItem.key as number]) 
    }
  } 

  const removeKeyFromSelectedList = (event: SgPickerCustomEvent<ItemsSelection>, list: number[], setList: React.Dispatch<React.SetStateAction<number[]>>) => {
    const selectedItem = event.detail.items.at(0);
    if(selectedItem !== undefined){
      setList(list.filter((id) => id !== (selectedItem.key as number)))
    }
  }

  const resetFilters = async() => {
    const coefficientPicker = document.querySelector('#coefficientPicker') as HTMLSgPickerElement;
    await coefficientPicker.unselectAllItems();
    setSelectedCoefficientIds([]);
    const termTypePicker = document.querySelector('#termTypePicker') as HTMLSgPickerElement;
    await termTypePicker.unselectAllItems();
    setSelectedTermTypeIds([]);
    onSearch([], []);
  }

  return (
      <div className="row gap-24px">
        <div className="col">
          <Label>{coefficientLabel}</Label>
          <SgPicker
            id="coefficientPicker"
            keyPath="id"
            placeholder=""
            summary={selectedCoefficientIds.length > 5 ? true : false}
            onReady={fetchIdsAndLabels}
            onItemsSelected={(a) => addKeyToSelectedList(a, selectedCoefficientIds, setSelectedCoefficientIds)}
            onItemsUnselected={(a) => removeKeyFromSelectedList(a, selectedCoefficientIds, setSelectedCoefficientIds)}
            onClear={() => setSelectedCoefficientIds([])}
          ></SgPicker>
        </div>

        <div className="col">
          <Label>Term Type</Label>
          <SgPicker
            id="termTypePicker"
            keyPath="id"
            placeholder=""
            onReady={fetchTermTypeIdsAndLabels}
            onItemsSelected={(a) => addKeyToSelectedList(a, selectedTermTypeIds, setSelectedTermTypeIds)}
            onItemsUnselected={(a) => removeKeyFromSelectedList(a, selectedTermTypeIds, setSelectedTermTypeIds)}
            onClear={() => setSelectedTermTypeIds([])}
          ></SgPicker>
        </div>

        <div className="col-md-2 gap-24px d-flex justify-content-center align-items-end">
          <button className="btn btn-outline-primary" onClick={resetFilters}>Reset</button>
          <button className="btn btn-primary" onClick={() => onSearch(selectedCoefficientIds, selectedTermTypeIds)}>Search</button>
        </div>
      </div>
  );
};
