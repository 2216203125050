import React from "react";
import { SgDatePicker, SgPicker } from "@sg-bootstrap/components/dist/react-library/src/components";
import { ItemsSelection, SgDatePickerCustomEvent, SgPickerCustomEvent } from "@sg-bootstrap/components";
import { Label } from "reactstrap";
import { RetentionRateApi } from "../../api/retentionrate.api";
import { dateToYearMonthNumber } from "../utils/mappers/mapperUtils";

export interface RetentionRateSearchFilterProps{
    initialCreditDate: Date,
    onSearch: (pcMnemos: string[], creditDates: number[]) => void,
}

export const RetentionRateSearchFilter: React.FC<RetentionRateSearchFilterProps> = ({initialCreditDate, onSearch}: RetentionRateSearchFilterProps) => {
  const [selectedPcMnemos, setSelectedPcMnemos] = React.useState<string[]>([]);
  const [selectedCreditDates, setSelectedCreditDates] = React.useState<number[]>([]);

  const fetchPcMnemos = async (event: SgPickerCustomEvent<string>) => {
    const pcMnemos = await RetentionRateApi.getPcMnemos();
    event.target.setItems(pcMnemos);
  };

  const initCreditDateValue = (event: SgDatePickerCustomEvent<void>) => {
    event.target.setSelection([initialCreditDate.getTime()]);
    setSelectedCreditDates([dateToYearMonthNumber(initialCreditDate)]);
  }

  const addToSelectedCreditDates = (event: SgDatePickerCustomEvent<{
    date: Date;
    timestamp: number;
    formatted: string;
  }>) => {
    const creditDate = dateToYearMonthNumber(event.detail.date);
    setSelectedCreditDates([...selectedCreditDates, creditDate]);
  }

  const removeFromSelectedCreditDates = (event: SgDatePickerCustomEvent<{
    date: Date;
    timestamp: number;
    formatted: string;
  }>) => {
    const creditDate = dateToYearMonthNumber(event.detail.date);
    setSelectedCreditDates(selectedCreditDates.filter((date) => date !== creditDate));
  }
  
  const addToSelectedPcMnemos = (event: SgPickerCustomEvent<ItemsSelection>) => {
    const selectedItem = event.detail.items.at(0);
    if(selectedItem?.label){
      setSelectedPcMnemos([...selectedPcMnemos, selectedItem.label]) 
    }
  } 

  const removeFromSelectedPcMnemos = (event: SgPickerCustomEvent<ItemsSelection>) => {
    const selectedItem = event.detail.items.at(0);
    if(selectedItem !== undefined){
      setSelectedPcMnemos(selectedPcMnemos.filter((pcMnemo) => pcMnemo !== selectedItem.label))
    }
  }

  const resetFilters = async () => {
    const pcMnemoPicker = document.querySelector('#pcMnemoPicker') as HTMLSgPickerElement;
    await pcMnemoPicker.unselectAllItems();
    setSelectedPcMnemos([]);
    const creditDatePicker = document.querySelector('#creditDatePicker') as HTMLSgDatePickerElement;
    await creditDatePicker.resetSelection();
    setSelectedCreditDates([]);
    onSearch([], []);
  }

  return (
      <div className="row gap-24px">
        <div className="col">
          <Label>PC mnemo</Label>
          <SgPicker
            id="pcMnemoPicker"
            keyPath="label"
            labelPath="label"
            placeholder=""
            summary={selectedPcMnemos.length > 5 ? true : false}
            onReady={fetchPcMnemos}
            onItemsSelected={addToSelectedPcMnemos}
            onItemsUnselected={removeFromSelectedPcMnemos}
            onClear={() => setSelectedPcMnemos([])}
          ></SgPicker>
        </div>

        <div className="col">
          <Label>Credit date</Label>
          <SgDatePicker
            id="creditDatePicker"
            placeholder=""
            selectionMode="month"
            itemSelection="multiple"
            displaySelectedDates
            onReady={initCreditDateValue}
            onDateSelected={addToSelectedCreditDates}
            onDateUnSelected={removeFromSelectedCreditDates}
            onInputCleared={() => setSelectedCreditDates([])}
          ></SgDatePicker>
        </div>

        <div className="col-md-2 gap-24px d-flex justify-content-center align-items-end">
          <button className="btn btn-outline-primary" onClick={resetFilters}>Reset</button>
          <button className="btn btn-primary" onClick={() => onSearch(selectedPcMnemos, selectedCreditDates)}>Search</button>
        </div>
      </div>
  );
};
