import React, { useState } from "react";
import { getUserEmail } from "../../helpers";
import { useGetUserProfile } from "../../queries/users";
import { UserClaim } from "../../types/common.typing";
import { If } from "./If";
import { PageForbidden } from "./PageForbidden";
import { PageNotAuthorized } from "./PageNotAuthorized";
interface AuthenticationProps {
    children: React.ReactNode;
}
export const Authentication: React.FC<AuthenticationProps> = ({ children }: AuthenticationProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [userClaims, setUserClaims] = useState<UserClaim[]>([]);
    const [isAuthorized, setIsAuthorized] = useState<boolean>(false);
    const { data: cerberusResponse, status, error } = useGetUserProfile(getUserEmail() ?? "", isLoading);

    React.useEffect(() => {
        switch(status) {
        case "error":
            setIsLoading(false);
            setIsAuthorized((error as Response)?.status !== 401);
            break;
        case "success":
            setIsLoading(false);
            setIsAuthorized(true);
            setUserClaims(cerberusResponse?.validatedRequestCProfit?.claims ?? []);
            break;
        default:
            setIsLoading(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status]);

   const doesUserHaveClaims = (): boolean => {
    return userClaims.find(x => x.domainId === "clientcontribution") !== undefined;
  }

  return (
    <>
      <If condition={isLoading}>
        <div className="spinner-grow spinner-grow-sm" role="status">
          Loading...
        </div>
      </If>
      <If condition={!isLoading}>
        <If condition={isAuthorized} elseComponent={<PageNotAuthorized />}>
          <If condition={doesUserHaveClaims()} elseComponent={<PageForbidden />}  >
            {children}
          </If>
        </If>
      </If>
    </>
  );
}