import React from "react";
import { AgGridReact } from "ag-grid-react";
import { RetentionRateSearchFilter } from "./RetentionRateSearchFilter";
import { ColDef, ColGroupDef } from "ag-grid-community";
import { RetentionRateDto } from "../../types/retentionrate.typing";
import { RetentionRateApi } from "../../api/retentionrate.api";
import { dateToYearMonthNumber } from "../utils/mappers/mapperUtils";

export const RetentionRateContainer: React.FC = () => {
  const DEFAULT_PAGINATION_SIZE = 10;
  const [retentionRates, setRetentionRates] = React.useState<RetentionRateDto[]>([]);
  const columns: (ColDef<RetentionRateDto> | ColGroupDef<RetentionRateDto>)[] = [
    { field: "creditDateMonth", flex: 2 },
    { field: "pcMnemo", flex: 2, headerName: "PC Mnemo", initialSort: "asc" },
    { field: "retentionRateRounded", headerName: "Retention Rate", flex: 2 },
    {
      headerName: "Coefficient",
      children:
        [
          { field: "coefficient.coefficientType", headerName: "Parameter", flex: 1 },
          { field: "coefficient.ssbl", headerName: "SSBL", flex: 1 },
          { field: "coefficient.termType", headerName: "Term Type", flex: 1 },
          { field: "coefficient.cap", headerName: "Cap", flex: 1 },
          { field: "coefficient.floor", headerName: "Floor", flex: 1 }
        ]
    }
  ];

  const search = (pcMnemos: string[], creditDates: number[]) => {
    RetentionRateApi.getRetentionRates(pcMnemos, creditDates).then((res) =>
      setRetentionRates(res.retentionRates)
    );
  };

  const initGrid = () => {
    const date = dateToYearMonthNumber(getLastMonthDate());
    search([], [date])
  }

  const getLastMonthDate = (): Date => {
    const date = new Date();
    let month = date.getMonth() - 1;
    let year = date.getFullYear();
    if (month === -1) {
      month = 11;
      year = year - 1;
    }
    date.setMonth(month);
    date.setFullYear(year);
    return date;
  }


  return (
    <div className="main-content">
      <div className="row pl-2">
        <div className="col-12 col-sm-8 p-4">
          <h3 className="display-3">C-Profit Client Contribution</h3>
          <h4>RR calculation</h4>
        </div>
      </div>

      <RetentionRateSearchFilter
        initialCreditDate={getLastMonthDate()}
        onSearch={search}
      ></RetentionRateSearchFilter>

      <hr />

      <div className="ag-theme-alpine">
        <AgGridReact
          defaultColDef={{
            sortable: true,
            filter: true,
            resizable: true,
          }}
          domLayout="autoHeight"
          rowData={retentionRates}
          columnDefs={columns}
          pagination={true}
          paginationPageSize={DEFAULT_PAGINATION_SIZE}
          onGridReady={initGrid}
          suppressHorizontalScroll={true}
          tooltipShowDelay={1000}
          paginationPageSizeSelector={[10, 25, 50]}
        ></AgGridReact>
      </div>
    </div>
  );
};
