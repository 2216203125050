import { useIntl } from "react-intl";
import { NavLink } from "reactstrap";

interface PageContactProps {
    pageTitleId: string;
    pageDescriptionId: string;
    pageGoHomeId: string;
    pageContactUsId: string;
}
export const PageContact: React.FC<PageContactProps> = ({pageTitleId, pageDescriptionId, pageGoHomeId, pageContactUsId }: PageContactProps) => {

    const intl = useIntl();
    return (
        <>
            <div className="col-lg-5  bg-white d-flex flex-column justify-content-between">
                <div>
                    <h1 className="d-none d-lg-block display-2 font-weight-medium line-height-1 pt-0 pb-3">
                        {intl.formatMessage({ id: pageTitleId })}
                    </h1>
                    <h1 className="d-lg-none display-3 font-weight-medium line-height-1 py-2">
                        {intl.formatMessage({ id: pageTitleId })}
                    </h1>

                    {/* Mobile */}
                    <p className="d-lg-none mb-5 h3 font-weight-medium text-secondary line-height-1">
                        {intl.formatMessage({ id: pageDescriptionId })}
                    </p>
                    {/* Desktop */}
                    <p className="d-none d-lg-block d-xxl-none display-3 font-weight-medium text-secondary w-75 line-height-1">
                        {intl.formatMessage({ id: pageDescriptionId })}
                    </p>

                    {/* XXL */}
                    <p className="d-none d-xxl-block display-3 font-weight-medium text-secondary w-75 line-height-1">
                        {intl.formatMessage({ id: pageDescriptionId })}
                    </p>
                </div>
                <div className="mb-5 d-none d-md-flex">
                    <NavLink className="btn btn-xl btn-primary" to="/">
                        {intl.formatMessage({ id: pageGoHomeId })}
                    </NavLink>
                    <a className="btn btn-xl btn-flat-secondary ml-1" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>
                        {intl.formatMessage({ id: pageContactUsId })}
                    </a>
                </div>
                <div className="d-block d-md-none">
                    <NavLink className="btn btn-xl btn-primary btn-block mb-1" to="/">
                        {intl.formatMessage({ id: pageGoHomeId })}
                    </NavLink>
                    <a className="btn btn-xl btn-flat-secondary ml-1 btn-block" href={`mailto:${process.env.REACT_APP_CONTACT_MAIL}`}>
                        {intl.formatMessage({ id: pageContactUsId })}
                    </a>
                </div>
                </div>
                <div className="col-4 px-0 position-relative">
                    <div className="d-none d-lg-block"
                        style={{ paddingTop: '100%', background: '#fff url(/404.svg) center no-repeat', backgroundSize: '130%' }} />
                </div>
    </>
    );
}