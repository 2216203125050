import * as React from "react";

export interface Props {
    condition: boolean;
    children: React.ReactNode;
    elseComponent?: React.ReactNode;
}

export const If: React.FC<Props> = ({ condition, children, elseComponent }) => (
  <>{condition ? children : elseComponent || null}</>
);
