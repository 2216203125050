import React from 'react';
import { PageContact } from './PageContact';

export const PageNotAuthorized: React.FC = () => {
  return (
    <div className="page-404 pt-5">
      <section className="section-2 mt-5 vh-100">
        <div className="container-fluid">
          <div className="container main-wrapper">
            <div className="row">
              <div className="col-lg-2 bg-white pt-5 pb-4 py-lg-0">
                <span className="d-block d-md-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '56px', fontFamily: 'Inter' }}>
                  401
              </span>
                <span className="d-none d-md-block d-lg-none text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ lineHeight: 1.2, fontSize: '56px', fontFamily: 'Inter' }}>
                  401
              </span>
                <span className="mt-n2 d-none d-lg-inline-block d-xl-none display-2 text-outline-socgen text-primary-alt font-weight-medium">
                  401
              </span>
                <span className="mt-n3 d-none d-xl-block display-1 text-outline-socgen text-primary-alt font-weight-medium"
                  style={{ fontSize: '96px', overflow: 'hidden' }}>
                  401
              </span>
              </div>
              <PageContact pageTitleId="page-401.title" pageDescriptionId="page-401.description" pageGoHomeId="page-401.go-home" pageContactUsId="page-401.contact-us" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};